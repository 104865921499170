export const exampleCommitMessageHook = `
{
    "ref": "refs/heads/master",
    "before": "8cc7ca218fcb4fa9fec797df229fc9d9b0c6e299",
    "after": "161d595b734b8c6fa3f639ffe1977ef8845effe4",
    "repository": {
      "id": 495332716,
      "node_id": "R_kgDOHYYtbA",
      "name": "garbage-repo-for-testing",
      "full_name": "snakemode/garbage-repo-for-testing",
      "private": true,
      "owner": {
        "name": "snakemode",
        "email": "contact@snkmo.de",
        "login": "snakemode",
        "id": 66012918,
        "node_id": "MDEyOk9yZ2FuaXphdGlvbjY2MDEyOTE4",
        "avatar_url": "https://avatars.githubusercontent.com/u/66012918?v=4",
        "gravatar_id": "",
        "url": "https://api.github.com/users/snakemode",
        "html_url": "https://github.com/snakemode",
        "followers_url": "https://api.github.com/users/snakemode/followers",
        "following_url": "https://api.github.com/users/snakemode/following{/other_user}",
        "gists_url": "https://api.github.com/users/snakemode/gists{/gist_id}",
        "starred_url": "https://api.github.com/users/snakemode/starred{/owner}{/repo}",
        "subscriptions_url": "https://api.github.com/users/snakemode/subscriptions",
        "organizations_url": "https://api.github.com/users/snakemode/orgs",
        "repos_url": "https://api.github.com/users/snakemode/repos",
        "events_url": "https://api.github.com/users/snakemode/events{/privacy}",
        "received_events_url": "https://api.github.com/users/snakemode/received_events",
        "type": "Organization",
        "site_admin": false
      },
      "html_url": "https://github.com/snakemode/garbage-repo-for-testing",
      "description": null,
      "fork": false,
      "url": "https://github.com/snakemode/garbage-repo-for-testing",
      "forks_url": "https://api.github.com/repos/snakemode/garbage-repo-for-testing/forks",
      "keys_url": "https://api.github.com/repos/snakemode/garbage-repo-for-testing/keys{/key_id}",
      "collaborators_url": "https://api.github.com/repos/snakemode/garbage-repo-for-testing/collaborators{/collaborator}",
      "teams_url": "https://api.github.com/repos/snakemode/garbage-repo-for-testing/teams",
      "hooks_url": "https://api.github.com/repos/snakemode/garbage-repo-for-testing/hooks",
      "issue_events_url": "https://api.github.com/repos/snakemode/garbage-repo-for-testing/issues/events{/number}",
      "events_url": "https://api.github.com/repos/snakemode/garbage-repo-for-testing/events",
      "assignees_url": "https://api.github.com/repos/snakemode/garbage-repo-for-testing/assignees{/user}",
      "branches_url": "https://api.github.com/repos/snakemode/garbage-repo-for-testing/branches{/branch}",
      "tags_url": "https://api.github.com/repos/snakemode/garbage-repo-for-testing/tags",
      "blobs_url": "https://api.github.com/repos/snakemode/garbage-repo-for-testing/git/blobs{/sha}",
      "git_tags_url": "https://api.github.com/repos/snakemode/garbage-repo-for-testing/git/tags{/sha}",
      "git_refs_url": "https://api.github.com/repos/snakemode/garbage-repo-for-testing/git/refs{/sha}",
      "trees_url": "https://api.github.com/repos/snakemode/garbage-repo-for-testing/git/trees{/sha}",
      "statuses_url": "https://api.github.com/repos/snakemode/garbage-repo-for-testing/statuses/{sha}",
      "languages_url": "https://api.github.com/repos/snakemode/garbage-repo-for-testing/languages",
      "stargazers_url": "https://api.github.com/repos/snakemode/garbage-repo-for-testing/stargazers",
      "contributors_url": "https://api.github.com/repos/snakemode/garbage-repo-for-testing/contributors",
      "subscribers_url": "https://api.github.com/repos/snakemode/garbage-repo-for-testing/subscribers",
      "subscription_url": "https://api.github.com/repos/snakemode/garbage-repo-for-testing/subscription",
      "commits_url": "https://api.github.com/repos/snakemode/garbage-repo-for-testing/commits{/sha}",
      "git_commits_url": "https://api.github.com/repos/snakemode/garbage-repo-for-testing/git/commits{/sha}",
      "comments_url": "https://api.github.com/repos/snakemode/garbage-repo-for-testing/comments{/number}",
      "issue_comment_url": "https://api.github.com/repos/snakemode/garbage-repo-for-testing/issues/comments{/number}",
      "contents_url": "https://api.github.com/repos/snakemode/garbage-repo-for-testing/contents/{+path}",
      "compare_url": "https://api.github.com/repos/snakemode/garbage-repo-for-testing/compare/{base}...{head}",
      "merges_url": "https://api.github.com/repos/snakemode/garbage-repo-for-testing/merges",
      "archive_url": "https://api.github.com/repos/snakemode/garbage-repo-for-testing/{archive_format}{/ref}",
      "downloads_url": "https://api.github.com/repos/snakemode/garbage-repo-for-testing/downloads",
      "issues_url": "https://api.github.com/repos/snakemode/garbage-repo-for-testing/issues{/number}",
      "pulls_url": "https://api.github.com/repos/snakemode/garbage-repo-for-testing/pulls{/number}",
      "milestones_url": "https://api.github.com/repos/snakemode/garbage-repo-for-testing/milestones{/number}",
      "notifications_url": "https://api.github.com/repos/snakemode/garbage-repo-for-testing/notifications{?since,all,participating}",
      "labels_url": "https://api.github.com/repos/snakemode/garbage-repo-for-testing/labels{/name}",
      "releases_url": "https://api.github.com/repos/snakemode/garbage-repo-for-testing/releases{/id}",
      "deployments_url": "https://api.github.com/repos/snakemode/garbage-repo-for-testing/deployments",
      "created_at": 1653296513,
      "updated_at": "2022-05-23T09:01:53Z",
      "pushed_at": 1657184693,
      "git_url": "git://github.com/snakemode/garbage-repo-for-testing.git",
      "ssh_url": "git@github.com:snakemode/garbage-repo-for-testing.git",
      "clone_url": "https://github.com/snakemode/garbage-repo-for-testing.git",
      "svn_url": "https://github.com/snakemode/garbage-repo-for-testing",
      "homepage": null,
      "size": 1,
      "stargazers_count": 0,
      "watchers_count": 0,
      "language": null,
      "has_issues": true,
      "has_projects": true,
      "has_downloads": true,
      "has_wiki": true,
      "has_pages": false,
      "forks_count": 0,
      "mirror_url": null,
      "archived": false,
      "disabled": false,
      "open_issues_count": 0,
      "license": null,
      "allow_forking": false,
      "is_template": false,
      "web_commit_signoff_required": false,
      "topics": [],
      "visibility": "private",
      "forks": 0,
      "open_issues": 0,
      "watchers": 0,
      "default_branch": "master",
      "stargazers": 0,
      "master_branch": "master",
      "organization": "snakemode"
    },
    "pusher": {
      "name": "davidwhitney",
      "email": "david@davidwhitney.co.uk"
    },
    "organization": {
      "login": "snakemode",
      "id": 66012918,
      "node_id": "MDEyOk9yZ2FuaXphdGlvbjY2MDEyOTE4",
      "url": "https://api.github.com/orgs/snakemode",
      "repos_url": "https://api.github.com/orgs/snakemode/repos",
      "events_url": "https://api.github.com/orgs/snakemode/events",
      "hooks_url": "https://api.github.com/orgs/snakemode/hooks",
      "issues_url": "https://api.github.com/orgs/snakemode/issues",
      "members_url": "https://api.github.com/orgs/snakemode/members{/member}",
      "public_members_url": "https://api.github.com/orgs/snakemode/public_members{/member}",
      "avatar_url": "https://avatars.githubusercontent.com/u/66012918?v=4",
      "description": "Experimental bits by @thisisjofrank and @davidwhitney "
    },
    "sender": {
      "login": "davidwhitney",
      "id": 186470,
      "node_id": "MDQ6VXNlcjE4NjQ3MA==",
      "avatar_url": "https://avatars.githubusercontent.com/u/186470?v=4",
      "gravatar_id": "",
      "url": "https://api.github.com/users/davidwhitney",
      "html_url": "https://github.com/davidwhitney",
      "followers_url": "https://api.github.com/users/davidwhitney/followers",
      "following_url": "https://api.github.com/users/davidwhitney/following{/other_user}",
      "gists_url": "https://api.github.com/users/davidwhitney/gists{/gist_id}",
      "starred_url": "https://api.github.com/users/davidwhitney/starred{/owner}{/repo}",
      "subscriptions_url": "https://api.github.com/users/davidwhitney/subscriptions",
      "organizations_url": "https://api.github.com/users/davidwhitney/orgs",
      "repos_url": "https://api.github.com/users/davidwhitney/repos",
      "events_url": "https://api.github.com/users/davidwhitney/events{/privacy}",
      "received_events_url": "https://api.github.com/users/davidwhitney/received_events",
      "type": "User",
      "site_admin": false
    },
    "created": false,
    "deleted": false,
    "forced": false,
    "base_ref": null,
    "compare": "https://github.com/snakemode/garbage-repo-for-testing/compare/8cc7ca218fcb...161d595b734b",
    "commits": [
      {
        "id": "161d595b734b8c6fa3f639ffe1977ef8845effe4",
        "tree_id": "373b3fe156bbc60da82977abba9c4d1eb596dbc8",
        "distinct": true,
        "message": "jkljlk",
        "timestamp": "2022-07-07T10:04:20+01:00",
        "url": "https://github.com/snakemode/garbage-repo-for-testing/commit/161d595b734b8c6fa3f639ffe1977ef8845effe4",
        "author": {
          "name": "David Whitney",
          "email": "david@davidwhitney.co.uk",
          "username": "davidwhitney"
        },
        "committer": {
          "name": "David Whitney",
          "email": "david@davidwhitney.co.uk",
          "username": "davidwhitney"
        },
        "added": [],
        "removed": [],
        "modified": [
          "test.txt"
        ]
      }
    ],
    "head_commit": {
      "id": "161d595b734b8c6fa3f639ffe1977ef8845effe4",
      "tree_id": "373b3fe156bbc60da82977abba9c4d1eb596dbc8",
      "distinct": true,
      "message": "jkljlk",
      "timestamp": "2022-07-07T10:04:20+01:00",
      "url": "https://github.com/snakemode/garbage-repo-for-testing/commit/161d595b734b8c6fa3f639ffe1977ef8845effe4",
      "author": {
        "name": "David Whitney",
        "email": "david@davidwhitney.co.uk",
        "username": "davidwhitney"
      },
      "committer": {
        "name": "David Whitney",
        "email": "david@davidwhitney.co.uk",
        "username": "davidwhitney"
      },
      "added": [],
      "removed": [],
      "modified": [
        "test.txt"
      ]
    }
  }

`.trim();
